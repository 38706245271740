<template>
  <section class="hire_container">
    <section v-for="(item, index) in componentItems" :key="index" class="w100">
      <Component :is="item" />
    </section>
    <countryGuideVue />
  </section>
</template>
<script setup lang="ts">
import Home from './components/home.vue';
import Tools from './components/tools.vue';
// import Partners from './components/partners/index.vue';
import ServiceIntro from './components/service-introduce/index.vue';
import Advance from './components/advance/index.vue';
// import Evaluation from './components/evaluation/index.vue';
import Computer from './components/computer/index.vue';
import countryGuideVue from './components/country-guide.vue';

useSeoHead();
const componentItems: any[] = [Home, Tools, 
// Partners, 
  ServiceIntro, Advance, 
  // Evaluation, 
  Computer];

</script>

<style lang="less" scoped>
.hire_container {
  // user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :deep(.arco-image-img) {
    vertical-align: middle;
    border-radius: inherit;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .w100 {
    width: 100%;
  }
}

@media screen and(max-width:1320px) and (min-width: 800px) {
  .hire_container {
    align-items: unset;
  }
}

@media screen and (min-width: 800px) {
  .part {
    padding: 0 32px;
  }

  .hire_container {
    min-width: 1344px;
  }
}

.footer__copyright {
  height: 60px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #11295f;

  span {
    opacity: 0.4;
  }

  span + span {
    margin-left: 24px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
<style lang="less">
@media screen and (max-width: 800px) {
  body {
    overflow-x: hidden;
  }
}
</style>
